<template>
  <b-overlay :show="loading" rounded="sm" no-fade>
    <div class="new-note" v-if="!isEdit">
      <quill-editor id="quil-content-new-note" style="background-color: white;" v-model="tempNewNote" :options="noteOption" @focus="addNewNote" />

      <div id="note-toolbar" class="d-flex justify-content-end">
        <button class="ql-bold"></button>
        <button class="ql-underline"></button>
        <button class="ql-background" value="#fdff32"></button>
        <button class="ql-list" value="ordered"></button>
        <button class="ql-list" value="bullet"></button>
        <button class="ql-link"></button>

        <b-button @click="saveClientNote" variant="primary" style="width: 60px;">
          Save
        </b-button>
      </div>
    </div>

    <div class="cardClass mb-2" v-for="(note, index) in notesData" :key="index">
      <div class="note-header">
        <div class="d-flex w-100 flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <div>
            <h6>{{ note.fullName }}</h6>
          </div>
          <div>
            <small class="text-muted" v-b-tooltip.hover.top="formatDateTime(note.created_at)">{{ formatTimeAgo(note.created_at, note.updated_at) }}</small>
          </div>
        </div>
      </div>

      <div class="notes" @click="clickNote(note)" v-if="!note.isEditing">
        <div class="notes-preview">
          <div v-html="note.note || 'No Note'"></div>
        </div>

        <div class="note-footer">
          <!-- <div class="note-date">{{ new Date(note.created_at).toLocaleString() }}</div> -->
        </div>
      </div>

      <div v-else>
        <quill-editor id="quil-content" style="background-color: white;" v-model="tempEditNote" :options="noteOption" />

        <div id="note-toolbar" class="d-flex justify-content-end">
          <button class="ql-bold"></button>
          <button class="ql-underline"></button>
          <button class="ql-background" value="#fdff32"></button>
          <button class="ql-list" value="ordered"></button>
          <button class="ql-list" value="bullet"></button>
          <button class="ql-link"></button>

          <b-button @click="updateClientNote(note)" variant="primary" style="width: 60px;">
            Update
          </b-button>

          <b-button @click="cancelNoteEdit(note)" variant="danger" class="ml-25" style="width: 60px;">
            Cancel
          </b-button>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import store from '@/store';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { BBreadcrumb, BOverlay } from 'bootstrap-vue';
import { quillEditor } from 'vue-quill-editor';
import { formatTimeAgo, formatDateTime } from '../helpers';

export default {
  components: {
    BOverlay,
    quillEditor,
    BBreadcrumb,
  },

  data() {
    const tempClientId = parseInt(router.currentRoute.params.id);

    const noteOption = {
      modules: {
        toolbar: '#note-toolbar',
      },
      placeholder: 'What is this note about?',
    };

    return {
      noteOption,
      loading: false,
      clientId: tempClientId,
      notesData: [],
      isEdit: false,
      tempEditNote: null,
      tempNewNote: null,
    };
  },

  created() {
    this.fetchNotes();
  },

  methods: {
    formatDateTime,
    formatTimeAgo,

    addNewNote() {
      // Yeni not ekleme işlemi burada gerçekleştirilir
    },

    fetchNotes() {
      this.loading = true;
      store
        .dispatch('client/getClientNotes', { clientId: this.clientId })
        .then((res) => {
          this.notesData = res.data;

          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    clickNote(val) {
      this.notesData.forEach((el) => {
        el.isEditing = false;
      });
      this.isEdit = true;
      val.isEditing = true;
      this.tempEditNote = val.note;
    },

    updateClientNote(val) {
      this.loading = true;

      store
        .dispatch('client/updateClientNote', {
          id: val.id,
          clientId: this.clientId,
          note: this.tempEditNote,
        })
        .then((res) => {
          this.loading = false;
          this.isEdit = false;
          val.isEditing = false;
          val.note = this.tempEditNote;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: '✔️ Client note has been updated',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    saveClientNote() {
      this.loading = true;

      var tempData = {
        clientId: this.clientId,
        note: this.tempNewNote,
      };

      store
        .dispatch('client/saveClientNote', tempData)
        .then((res) => {
          this.loading = false;
          this.tempNewNote = null;
          this.notesData.unshift(res.data);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: '✔️ Client Note has been added',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    cancelNoteEdit(val) {
      val.isEditing = false;
      this.tempEditNote = val.note;
      this.isEdit = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.cardClass {
  padding: 1.25rem;
}

.new-note {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: solid 1px transparent;
  transition: border 0.3s;
  border-radius: 5px;
}

.notes {
  transition: border 0.3s;
  padding: 2px;
  border: 1px solid transparent;
}

.notes:hover {
  background-color: #f9f8f8;
  border-radius: 5px;
  cursor: pointer;
  padding: 2px;
  border-color: #ccc;
}

.note-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  // margin-bottom: 10px;
}

.note-user {
  font-weight: bold;
  width: 100%;
}

.note-footer {
  text-align: right;
}

.note-date {
  font-weight: bold;
}

.notes-preview {
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: solid 1px transparent;
  transition: border 0.3s;
  border-radius: 5px;
}

.cardClass + .cardClass {
  border-top: 1px solid #ddd;
  padding-top: 20px;
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
    border-top-right-radius: 0rem;
    border-top-left-radius: 0rem;
    min-height: 50px;
  }

  + #note-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

#quil-content-new-note ::v-deep {
  > .ql-container {
    border-bottom: 0;
    border-top-right-radius: 0rem;
    border-top-left-radius: 0rem;
    min-height: 50px;
  }

  + #note-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
</style>

<style lang="scss"></style>
