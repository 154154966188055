<template>
  <div>
    <b-overlay :show="loading" rounded="sm" no-fade>
      <b-row v-if="clientData != null">
        <b-col cols="12">
          <b-card>
            <div class="clientTitle mb-1" @click="handleNameClick" v-if="!isNameEditing">{{ clientData.name }}</div>
            <b-input v-else class="mb-1" autofocus v-model="clientData.name" @blur="updateClientName" @keyup.enter="updateClientNameEnter" />

            <span @click="activeTab = 1" :class="{ activeMenu: activeTab === 1, passiveMenu: activeTab !== 1 }"> <i class="fa-solid fa-table-columns mr-25 "></i> Overview </span>
            <span @click="activeTab = 2" :class="{ activeMenu: activeTab === 2, passiveMenu: activeTab !== 2 }" v-if="$Can('client_projects_tab')"> <i class="fa-solid fa-border-all mr-25 "></i> Projects </span>
            <span @click="activeTab = 3" :class="{ activeMenu: activeTab === 3, passiveMenu: activeTab !== 3 }" v-if="$Can('client_reports_tab')"> <i class="fa-solid fa-chart-line mr-25"></i> Reports </span>
            <span @click="activeTab = 4" :class="{ activeMenu: activeTab === 4, passiveMenu: activeTab !== 4 }" v-if="$Can('client_retainer_tab')"> <i class="fa-solid fa-comment-dots mr-25 "></i> Retainer </span>
            <span @click="activeTab = 5" :class="{ activeMenu: activeTab === 5, passiveMenu: activeTab !== 5 }" v-if="$Can('client_notes_tab')"> <i class="fa-solid fa-comment-dots mr-25 "></i> Notes </span>

            <hr />

            <div v-if="activeTab == 1">
              <div class="cardClass">
                <div class="subTitle">Brief information about the client</div>
                <div v-if="!isDescriptionEditing" class="description" @click="handleDescriptionClick(clientData)">
                  <span v-html="clientData.description || 'No Description'"> </span>
                </div>

                <div v-else>
                  <quill-editor id="quil-content" style="background-color: white;" v-model="editedDescription" :options="descriptionOption" />

                  <div id="description-toolbar" class="d-flex justify-content-end">
                    <button class="ql-bold"></button>
                    <button class="ql-underline"></button>
                    <button class="ql-background" value="#fdff32"></button>
                    <button class="ql-list" value="ordered"></button>
                    <button class="ql-list" value="bullet"></button>
                    <button class="ql-link"></button>

                    <b-button @click="updateClientDescription" variant="primary" style="width: 60px;">
                      Update
                    </b-button>

                    <b-button @click="cancelDescriptionEdit" variant="danger" class="ml-25" style="width: 60px;">
                      Cancel
                    </b-button>
                  </div>
                </div>

                <b-row class="mt-2">
                  <b-col cols="12" sm="6" md="4" xl="3">
                    <b-form-group>
                      <label for="ContactName">Contact Name</label>
                      <b-form-input :disabled="!$Can('client_edit_btn')" id="ContactName" @focus="onInputFocus('contactName')" @blur="onInputBlur('contactName')" v-model="clientData.contactName" type="text" />
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" sm="6" md="4" xl="3">
                    <b-form-group>
                      <label for="contactTitle">Contact Title</label>
                      <b-form-input :disabled="!$Can('client_edit_btn')" id="contactTitle" @focus="onInputFocus('contactTitle')" @blur="onInputBlur('contactTitle')" v-model="clientData.contactTitle" type="text" />
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" sm="6" md="4" xl="3">
                    <b-form-group>
                      <label for="mainPhone">Main Phone</label>
                      <b-form-input :disabled="!$Can('client_edit_btn')" id="mainPhone" @focus="onInputFocus('mainPhone')" @blur="onInputBlur('mainPhone')" v-model="clientData.mainPhone" type="text" />
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" sm="6" md="4" xl="3">
                    <b-form-group>
                      <label for="website">Website</label>
                      <b-form-input :disabled="!$Can('client_edit_btn')" id="website" @focus="onInputFocus('website')" @blur="onInputBlur('website')" v-model="clientData.website" type="text" />
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" sm="6" md="4" xl="3">
                    <ValidationObserver ref="formObserver">
                      <b-form-group>
                        <label for="email">Email</label>
                        <b-form-input :disabled="!$Can('client_edit_btn')" id="email" @focus="onInputFocus('email')" @blur="onInputBlur('email')" v-model="clientData.email" type="email" />
                      </b-form-group>
                    </ValidationObserver>
                  </b-col>

                  <b-col cols="12" sm="6" md="4" xl="3">
                    <b-form-group>
                      <label for="businessAddress">Address</label>
                      <b-form-input :disabled="!$Can('client_edit_btn')" id="businessAddress" @focus="onInputFocus('businessAddress')" @blur="onInputBlur('businessAddress')" v-model="clientData.businessAddress" type="text" />
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" sm="6" md="4" xl="3">
                    <b-form-group>
                      <label for="country">Country</label>
                      <b-form-input :disabled="!$Can('client_edit_btn')" id="country" @focus="onInputFocus('country')" @blur="onInputBlur('country')" v-model="clientData.country" type="text" />
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" sm="6" md="4" xl="3">
                    <b-form-group>
                      <label for="bestContactMethod">Best Contact Method</label>
                      <b-form-input :disabled="!$Can('client_edit_btn')" id="bestContactMethod" @focus="onInputFocus('bestContactMethod')" @blur="onInputBlur('bestContactMethod')" v-model="clientData.bestContactMethod" type="text" />
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" sm="6" md="4" xl="3" v-if="$Can('client_archive_btn')">
                    <b-form-group>
                      <label for="archived">Archived</label>
                      <b-form-checkbox id="archived" v-model="clientData.archived" switch @change="updateClient()" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </div>

            <div v-if="activeTab == 2">
              <projects-component></projects-component>
            </div>

            <div v-if="activeTab == 3">
              <reports-component :client-clockify-id="clientData.clockifyId" :client-name="clientData.name"></reports-component>
            </div>

            <div v-if="activeTab == 4">
              <retainer-component :client-clockify-id="clientData.clockifyId"></retainer-component>
            </div>
            <div v-if="activeTab == 5">
              <notes-component></notes-component>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import store from '@/store';
import router from '@/router';
import { ref, onUnmounted } from '@vue/composition-api';
import clientStoreModule from './clientStoreModule';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { quillEditor } from 'vue-quill-editor';
import vSelect from 'vue-select';
import { BFormDatepicker } from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import 'quill/dist/quill.snow.css';

import NotesComponent from './NotesComponent.vue';
import ProjectsComponent from './ProjectsComponent.vue';

import ReportsComponent from './ReportsComponent.vue';
import RetainerComponent from './RetainerComponent.vue';

export default {
  components: {
    ToastificationContent,
    quillEditor,
    vSelect,
    BFormDatepicker,
    NotesComponent,
    ProjectsComponent,
    ReportsComponent,
    RetainerComponent,
    ValidationProvider,
    ValidationObserver,
  },

  setup() {
    const CLIENT_APP_STORE_MODULE_NAME = 'client';

    // Register module
    if (!store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.registerModule(CLIENT_APP_STORE_MODULE_NAME, clientStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.unregisterModule(CLIENT_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    const descriptionOption = {
      modules: {
        toolbar: '#description-toolbar',
      },
      placeholder: 'What is this task about?',
    };

    const savedActiveTab = localStorage.getItem('activeClientTab');

    return {
      selectedUserId: null,
      selectedUserTitle: null,

      selectedContactId: null,
      selectedContactTitle: null,

      tempMemberRole: null,
      tempContactRole: null,
      users: [],
      contacts: [],
      editedDescription: null,
      descriptionOption,

      isNameEditing: false,
      isDescriptionEditing: false,
      loading: true,
      activeTab: 1,

      clientData: {},
      initialData: {},
    };
  },

  mounted() {
    this.getClientById();
  },
  watch: {
    activeTab(val) {
      localStorage.setItem('activeClientTab', val);
    },
  },

  methods: {
    handleDescriptionClick(clientData) {
      if (this.$Can('client_edit_btn')) {
        this.isDescriptionEditing = true;
        this.editedDescription = clientData.description;
      }
    },

    handleNameClick(clientData) {
      if (this.$Can('client_edit_btn')) {
        this.isNameEditing = true;
      }
    },

    onInputFocus(fieldName) {
      this.initialData[fieldName] = this.clientData[fieldName];
    },
    onInputBlur(fieldName) {
      if (fieldName === 'email') {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (!emailRegex.test(this.clientData.email)) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              text: 'Please enter a valid email address.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
          return;
        }
      }

      if (this.clientData[fieldName] !== this.initialData[fieldName]) {
        this.updateClient();
      }
    },

    getClientById() {
      this.loading = true;
      const _id = parseInt(router.currentRoute.params.id);

      store
        .dispatch('client/getClientById', { id: _id })
        .then((res) => {
          this.clientData = res.data;

          const savedActiveTab = localStorage.getItem('activeClientTab');
          this.activeTab = savedActiveTab ? parseInt(savedActiveTab, 10) : 1;

          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    updateClientName() {
      this.loading = true;
      this.isNameEditing = false;
      store
        .dispatch('client/updateClient', this.clientData)
        .then((res) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: '✔️ Client Name has been updated',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    updateClientNameEnter() {
      this.isNameEditing = false;
    },

    updateClientDescription() {
      this.loading = true;
      this.clientData.description = this.editedDescription;
      store
        .dispatch('client/updateClient', this.clientData)
        .then((res) => {
          this.loading = false;
          this.isDescriptionEditing = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: '✔️ Client Description has been updated',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    cancelDescriptionEdit() {
      this.isDescriptionEditing = false;
      this.editedDescription = this.clientData.description;
    },

    updateClient() {
      this.loading = true;
      store
        .dispatch('client/updateClient', this.clientData)
        .then((res) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: '✔️ Client has been updated',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
span.activeMenu {
  font-weight: 700;
  color: black;
  padding-bottom: 2px;
  box-shadow: inset 0 -2px #6d6e6f;
  transition: padding 0.3s;
  padding: 5px 10px 5px;
  margin-right: 10px;
}

span.passiveMenu {
  font-weight: 400;
  color: #6d6e6f;
  padding-bottom: 2px;
  transition: padding 0.3s;
  padding: 5px 10px 5px;
  margin-right: 10px;
}

span.activeMenu:hover {
  background-color: #f9f8f8;
  padding: 5px 10px 5px;
  cursor: pointer;
  transition: padding 0.3s;
}

span.passiveMenu:hover {
  background-color: #f9f8f8;
  box-shadow: inset 0 -2px #afabac;
  padding: 5px 10px 5px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  cursor: pointer;
  transition: padding 0.3s;
}

.clientTitle {
  font-size: 1.75rem;
  font-weight: 400;
  transition: padding 0.3s;
  border: solid 1px transparent;
  border-radius: 5px;
}

.clientTitle:hover {
  border-color: #ccc;
  background-color: #f9f8f8;
  cursor: pointer;
}

.subTitle {
  font-size: 1.5rem;
  margin-bottom: 0.25rem;
  font-weight: 500;
}

.person {
  display: flex;
  align-items: center;
  padding: 10px;
  border: solid 1px transparent;
  border-radius: 5px;
  transition: border 0.3s;
  height: 110px;
  max-width: 300px;
  min-width: 200px;
  background-color: #fff;
  margin-right: 10px;
  margin-top: 10px;
}

.person:hover {
  background-color: #f9f8f8;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px;
  border-color: #ccc;
}

.description {
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: solid 1px transparent;
  transition: border 0.3s;
  border-radius: 5px;

  // background-color: #00000003;
}

.description:hover {
  background-color: #f9f8f8;
  border-radius: 5px;
  cursor: pointer;
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-color: #ccc;
}

.name {
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
    border-top-right-radius: 0rem;
    border-top-left-radius: 0rem;
    min-height: 50px;
  }

  + #description-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.cardClass {
  padding: 1.25rem;
}
</style>
