<template>
  <b-overlay :show="loading" rounded="sm" no-fade>
    <b-row>
      <b-col cols="12" xl="1" md="1" sm="4">
        <input class="dataRange mt-1" type="text" ref="startDateInput" placeholder="Start Date" @change="updateDateRange()" :value="startDate" />
      </b-col>
      <b-col cols="12" xl="1" md="1" sm="4">
        <input class="dataRange mt-1" type="text" ref="endDateInput" placeholder="End Date" @change="updateDateRange()" :value="endDate" />
      </b-col>

      <b-col cols="12" xl="1" md="1" sm="4">
        <b-button @click="clearDates" class="mt-1" variant="warning">
          Clear
        </b-button>
      </b-col>

      <b-col cols="12" xl="2" lg="2" md="2" sm="6">
        <b-alert variant="secondary" class="mt-1" show>
          <div class="alert-body">
            <span class="text-center h5"><strong> Active Until: </strong> {{ activeUntil }}</span>
          </div>
        </b-alert>
      </b-col>

      <b-col cols="12" xl="6" lg="6" md="6" sm="12">
        <b-alert variant="success" class="mt-1" show>
          <div class="alert-body d-flex justify-content-between">
            <span class="h5"><strong> Total contract hours: </strong>{{ totalAdded }}</span>

            <span class="h5"><strong> Total hours used: </strong>{{ totalUsed }}</span>
            <span class="h5"><strong> Month Remaining: </strong>{{ monthRemaining }}</span>
            <!-- <span class="h5"
              ><strong> Next 3 Months Remaining: </strong
              >{{ nextRemaining }}</span
            > -->
          </div>
        </b-alert>
      </b-col>

      <b-col cols="12" xl="1" md="1" sm="6">
        <b-button @click="openModal" class="mt-1" variant="primary" type="submit">
          Add
        </b-button>
      </b-col>
    </b-row>

    <div class="todo-app-list">
      <!-- Todo List -->
      <!-- <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="todo-task-list-wrapper list-group scroll-area"> -->
      <b-overlay>
        <div class="todo-header">
          <div class="header-cell w-16" style="font-weight: bold;">Period</div>
          <div class="header-cell w-15" style="font-weight: bold;">Start</div>
          <div class="header-cell w-15" style="font-weight: bold;">End</div>
          <div class="header-cell w-10" style="font-weight: bold;">
            Balance Added
          </div>
          <div class="header-cell w-10" style="font-weight: bold;">
            Monthly Use
          </div>
          <div class="header-cell w-10" style="font-weight: bold;">
            Balance Transferred
          </div>
          <div class="header-cell w-14" style="font-weight: bold;">
            Billable Hours Logged
          </div>
          <div class="header-cell w-5" style="font-weight: bold; white-space: nowrap;">
            Invoiced
          </div>
          <div class="header-cell w-5" style="font-weight: bold;">Paid</div>
        </div>

        <div v-for="(task, index) in monthlyRetainerRecords" :key="index">
          <div class="task-content">
            <div class="todo-cell w-16 d-flex justify-content-center">
              <i v-if="task.subHours.length > 0" :class="['fa-solid', 'fa-caret-' + (task.isExpanded ? 'down' : 'right'), 'mr-1 text-dark']" style="font-size: 1rem; cursor:pointer" @click="toggleExpand(task)"></i>
              <i v-else :class="['fa-solid', 'fa-caret-right', 'mr-1']" style="font-size: 1rem; cursor:pointer; visibility: hidden"></i>
              <div class="task-name" @click="openModalDetail(task)">
                <div class="w-100">{{ task.month }} {{ task.year }}</div>
              </div>
            </div>

            <div class="todo-cell w-15  hover-background" @click="openModalDetail(task)">
              <div class="task-name">
                <div class="w-100">{{ task.startDate }}</div>
              </div>
            </div>

            <div class="todo-cell w-15  hover-background" @click="openModalDetail(task)">
              <div class="task-name">
                <div class="w-100">{{ task.endDate }}</div>
              </div>
            </div>

            <div class="todo-cell w-10  hover-background" @dblclick="startEditing(task)">
              <div class="task-name">
                <div v-if="isEditing != task.id" class="w-100">
                  {{ task.hoursTotal }}
                  <strong class="text-success ml-2" v-if="task.additionalTotal > 0"> + {{ task.additionalTotal }}</strong>
                </div>
                <input v-else ref="taskHoursInput" class="w-100" v-model="editedTaskHours" @keyup.enter="saveTaskHours(task)" />
              </div>
            </div>
            <div class="todo-cell w-10  hover-background" @dblclick="startEditingUsedHours(task)">
              <div class="task-name">
                <div v-if="isEditingUsed != task.id" class="w-100">
                  {{ task.hoursUsed }}
                </div>
                <input v-else ref="taskHoursInput" class="w-100" v-model="editedUsedHours" @keyup.enter="saveUsedHours(task)" />
              </div>
            </div>

            <div class="todo-cell w-10  hover-background">
              <div class="task-name">
                <div v-if="task.monthRemaining > 0" class="w-100">
                  {{ task.monthRemaining }}
                </div>

                <div v-else class="w-100 text-danger">
                  <strong>{{ task.monthRemaining }} </strong>

                  <span v-if="task.monthRemaining == 0" style="font-size: smaller;">
                    <br />
                    Balance is over</span
                  >

                  <span v-if="task.monthRemaining < 0" style="font-size: smaller;">
                    <br />
                    Future balance charged</span
                  >
                </div>
              </div>
            </div>
            <div class="todo-cell w-14 hover-background" style="cursor: pointer; transition: background-color 0.2s;" @click="openModalDetail(task)">
              <div class="task-name">
                <div class="w-100">{{ hoursFormat(task.billableHours) }}</div>
              </div>
            </div>

            <div class="todo-cell w-5  hover-background">
              <div class="task-name">
                <div class="custom-checkbox text-center">
                  <span class="checkbox-icon" :class="{ checked: task.invoiced }" @click="updateInvoiced(task)"></span>
                </div>
              </div>
            </div>

            <div class="todo-cell w-5  hover-background">
              <div class="task-name">
                <div class="custom-checkbox text-center">
                  <span class="checkbox-icon" :class="{ checked: task.paid }" @click="updatePaid(task)"></span>
                </div>
              </div>
            </div>
          </div>

          <!-- SUB TASKSSSSSSSSS -->
          <div v-for="(subtask, index) in task.subHours" :key="index">
            <div v-if="task.isExpanded" class="subtask-content">
              <div class="todo-cell w-46 hover-background" @dblclick="startEditingSubtaskDescription(subtask)" style="cursor: pointer; transition: background-color 0.2s;">
                <div class="task-name ml-5">
                  <div v-if="isEditingSubDescription != subtask.id" class="w-100">
                    {{ subtask.description }}
                  </div>
                  <input v-else ref="taskHoursInput" class="w-100" v-model="editedSubtaskDescription" @keyup.enter="saveSubtaskDescription(subtask)" />
                </div>
              </div>
              <div class="todo-cell w-44 hover-background" @dblclick="startEditingSubtaskHours(subtask)" style="cursor: pointer; transition: background-color 0.2s;">
                <div class="task-name">
                  <!-- <div>{{ subtask.hoursTotal }}</div> -->

                  <div class="task-name">
                    <div v-if="isEditingSubhours != subtask.id" class="w-100">
                      {{ subtask.hoursTotal }}
                    </div>
                    <input v-else ref="taskHoursInput" class="w-100" v-model="editedSubtaskHours" @keyup.enter="saveSubtaskHours(subtask)" />
                  </div>
                </div>
              </div>

              <div class="todo-cell w-5 hover-background" style="cursor: pointer; transition: background-color 0.2s;">
                <div class="task-name">
                  <div class="custom-checkbox text-center">
                    <span class="checkbox-icon" :class="{ checked: subtask.paid }" @click="updatePaidSubtask(subtask)"></span>
                  </div>
                </div>
              </div>

              <div class="todo-cell w-5 hover-background" style="cursor: pointer; transition: background-color 0.2s;">
                <div class="custom-checkbox text-center">
                  <span class="checkbox-icon" :class="{ checked: subtask.invoiced }" @click="updateInvoicedSubtask(subtask)"></span>
                </div>
              </div>
            </div>
          </div>
          <!-- SUB TASK FINISHEDDD -->
        </div>
        <div class="no-results" v-if="monthlyRetainerRecords.length < 1">
          <h5>No Items Found</h5>
        </div>
      </b-overlay>
    </div>

    <b-modal v-model="modalVisible" title="Add New Retainer Agreement" :hide-footer="true">
      <form @submit.prevent="createRetainer">
        <!-- Form fields for start date, end date, hours per month, and monthly agreed price -->
        <b-form-group label="Retainer Period" label-for="startDate">
          <div class="d-flex justify-content-between">
            <flat-pickr placeholder="Date Range" v-model="newRetainerDateRange" class="form-control" required :config="config" />
            <b-button @click="clearNewRetainerDateRange" class="ml-1" variant="success">Clear</b-button>
          </div>
        </b-form-group>

        <b-form-group label="Hours Per Month" label-for="hoursPerMonth">
          <b-form-input type="number" id="hoursPerMonth" v-model="newRetainer.hoursPerMonth" required></b-form-input>
        </b-form-group>

        <b-form-group label="Monthly Agreed Price" label-for="monthlyAgreedPrice">
          <b-form-input type="number" id="monthlyAgreedPrice" v-model="newRetainer.monthlyAgreedPrice" required></b-form-input>
        </b-form-group>

        <!-- Modal actions -->
        <div class="d-flex justify-content-end">
          <b-button variant="secondary" class="mr-1" @click="closeModal">Cancel</b-button>
          <b-button type="submit" class="mr-1" variant="primary">Save</b-button>
        </div>
      </form>
    </b-modal>

    <div v-if="isRetainerSidebarActive">
      <retainer-sidebar
        :is-retainer-sidebar-active="isRetainerSidebarActive"
        @get-retainers="getRetainers"
        :clockify-reports="detailedItem.clockifys"
        v-model="isRetainerSidebarActive"
        :retainer-id="detailedItem.id"
        :start-date="detailedItem.startDate"
        :end-date="detailedItem.endDate"
        :client-id="clientClockifyId"
      />
    </div>
  </b-overlay>
</template>

<script>
import store from '@/store';
import vSelect from 'vue-select';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import flatPickr from 'vue-flatpickr-component';
import { required, email } from '@validations';
import router from '@/router';
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { convertToSecondISO, convertToFirstISO, hoursFormat, getTodayDate, getYesterdayDate, getThisWeekDate, getLastWeekDate, getThisMonthDate, getLastMonthDate, formatDateTime } from '../helpers';
import ClientProjectReport from './ClientProjectReport';
import RetainerSidebar from './RetainerSidebar';
import { BBadge } from 'bootstrap-vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

export default {
  components: {
    vSelect,
    ToastificationContent,
    Treeselect,
    AppCollapseItem,
    AppCollapse,
    flatPickr,
    ClientProjectReport,
    RetainerSidebar,
    BBadge,
    VuePerfectScrollbar,
    VuePerfectScrollbar,
  },

  props: {
    clientClockifyId: String,
    clientName: String,
  },

  computed: {},

  created() {
    // var firstDayOfYear = new Date(new Date().getFullYear(), 0, 1);
    // var lastDayOfYear = new Date(new Date().getFullYear(), 11, 31);

    // this.rangeDate = `${this.formatDate(firstDayOfYear)} to ${this.formatDate(lastDayOfYear)}`;

    // this.rangeDate = String(yesterday) + ' to ' + String(today);
    // this.getRetainers();

    this.getRetainers();
  },

  data() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    };

    return {
      perfectScrollbarSettings,

      dateRangeStart: null,
      dateRangeEnd: null,
      newRetainerDateRange: null,
      newRetainerDateRange: null,

      isEditing: null,
      editedTaskHours: null,

      isEditingUsed: null,
      editedUsedHours: null,

      isEditingSubhours: null,
      editedSubtaskHours: null,
      isEditingSubDescription: null,
      editedSubtaskDescription: null,

      isEditingSubDescription: null,
      editedSubtaskDescription: null,

      config: {
        dateFormat: 'd-m-Y',
        mode: 'range',
      },

      monthlyRetainerRecords: [],

      monthlyRetainerRecords: [],

      modalVisible: false,
      newRetainer: {
        startDate: '',
        endDate: '',
        hoursPerMonth: 0,
        monthlyAgreedPrice: 0,
      },

      modalDetailVisible: false,
      isRetainerSidebarActive: false,
      detailedItem: {
        startDate: null,
        endDate: null,
        clientId: null,
      },
      isRetainerSidebarActive: false,
      detailedItem: {
        startDate: null,
        endDate: null,
        clientId: null,
      },
      newInvoiceFile: null,

      retainerList: [],
      activeUntil: null,
      monthRemaining: 0,
      totalUsed: 0,
      totalAdded: 0,
      nextRemaining: 0,

      summaryReports: [],
      selectedProjects: [],
      selectedProjectIds: [],
      projects: [],
      totalClient: 0,
      isArchive: 0,
      showOptions: ['Active', 'Archived'],
      totalTime: 0,
      loading: false,
      totalEntries: 0,
      rangeDate: null,
      selectedOption: 'Active',
      startDate: null,
      endDate: null,
    };
  },

  mounted() {
    const startDateInput = this.$refs.startDateInput;
    const endDateInput = this.$refs.endDateInput;

    flatpickr(startDateInput, {
      dateFormat: 'd-m-Y',
      onClose: () => {
        this.updateDateRange();
      },
    });

    flatpickr(endDateInput, {
      dateFormat: 'd-m-Y',
      onClose: () => {
        this.updateDateRange();
      },
    });
  },

  methods: {
    hoursFormat,
    getTodayDate,
    getYesterdayDate,
    getThisWeekDate,
    getLastWeekDate,
    getThisMonthDate,
    getLastMonthDate,
    formatDateTime,
    convertToFirstISO,
    convertToSecondISO,

    updateDateRange() {
      const startDate = this.$refs.startDateInput.value;
      const endDate = this.$refs.endDateInput.value;
      this.startDate = this.$refs.startDateInput.value;
      this.endDate = this.$refs.endDateInput.value;
      this.rangeDate = startDate && endDate ? `${startDate} to ${endDate}` : null;
      this.getRetainers();
    },

    clearNewRetainerDateRange() {
      this.newRetainerDateRange = null;
    },
    clearDates() {
      this.startDate = null;
      this.endDate = null;
      this.rangeDate = 'clear';
      this.getRetainers();
    },

    formatDate(date) {
      const day = date.getDate();
      const month = date.getMonth() + 1; // Month is 0-based
      const year = date.getFullYear();

      return `${day < 10 ? '0' : ''}${day}-${month < 10 ? '0' : ''}${month}-${year}`;
    },

    getRetainers() {
      this.loading = true;
      const _id = parseInt(router.currentRoute.params.id);

      store
        .dispatch('client/getRetainerList', {
          rangeDate: this.rangeDate,
          clientId: _id,
          clockifyId: this.clientClockifyId,
        })
        .then((res) => {
          this.monthlyRetainerRecords = [];
          if (this.rangeDate == null || this.rangeDate == 'clear') {
            this.rangeDate = `${res.data.startDate} to ${res.data.endDate}`;
            this.startDate = res.data.startDate;
            this.endDate = res.data.endDate;
          }

          this.monthlyRetainerRecords = res.data.retainers;
          this.activeUntil = res.data.activeUntil;
          this.monthRemaining = res.data.monthRemaining;
          this.nextRemaining = res.data.nextRemaining;
          this.totalUsed = res.data.totalUsed;
          this.totalAdded = res.data.totalAdded;

          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    createRetainer() {
      if (this.newRetainerDateRange != null && this.newRetainer.hoursPerMonth > 0) {
        this.loading = true;
        const _id = parseInt(router.currentRoute.params.id);

        store
          .dispatch('client/createRetainer', {
            clientId: _id,
            hoursTotal: this.newRetainer.hoursPerMonth,
            pricePerHour: this.newRetainer.monthlyAgreedPrice,
            dateRange: this.newRetainerDateRange,
          })
          .then((res) => {
            this.retainerList = [];
            this.getRetainers();

            if (res.data == 'ok') {
            } else {
              if (res.data.unavailableMonths.length > 0) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'An error occurred',
                    text: 'Selected months already have existing retainers.',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                });
                this.loading = false;
              }
            }
            this.modalVisible = false;
          })
          .catch((error) => {
            this.loading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'An error occurred',
                text: 'Please try again later or contact support.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            });
          });
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'An error occurred',
            text: 'Please fill in the required fields.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }
    },

    startEditingSubtaskDescription(task) {
      this.isEditingSubDescription = task.id;
      this.editedSubtaskDescription = task.description;
    },

    startEditingSubtaskHours(task) {
      this.isEditingSubhours = task.id;
      this.editedSubtaskHours = task.hoursTotal;
    },

    startEditing(task) {
      this.isEditing = task.id;
      this.editedTaskHours = task.hoursTotal;
    },

    startEditingUsedHours(task) {
      this.isEditingUsed = task.id;
      this.editedUsedHours = task.hoursUsed;
    },

    saveTaskHours(task) {
      this.loading = true;
      task.hoursTotal = this.editedTaskHours;
      store
        .dispatch('client/updateRetainer', task)
        .then((res) => {
          this.isEditing = null;
          this.editedTaskHours = null;
          this.getRetainers();
        })
        .catch((error) => {
          this.isEditing = null;
          this.editedTaskHours = null;

          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    saveUsedHours(task) {
      if (this.editedUsedHours > task.monthRemaining + task.hoursUsed) {
        this.$swal({
          title: 'Exceeding Hours',
          text: 'Current month has ' + task.monthRemaining + ' hours available. The exceeding hours will be charged from future balance.',
          icon: 'danger',
          showCancelButton: true,
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-danger ml-1',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.loading = true;
            task.hoursUsed = this.editedUsedHours;
            store
              .dispatch('client/updateRetainer', task)
              .then((res) => {
                this.isEditingUsed = null;
                this.editedUsedHours = null;
                this.getRetainers();
              })
              .catch((error) => {
                this.isEditingUsed = null;
                this.editedUsedHours = null;

                this.loading = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'An error occurred',
                    text: 'Please try again later or contact support.',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                });
              });
          }
        });
      } else {
        this.loading = true;
        task.hoursUsed = this.editedUsedHours;
        store
          .dispatch('client/updateRetainer', task)
          .then((res) => {
            this.isEditingUsed = null;
            this.editedUsedHours = null;
            this.getRetainers();
          })
          .catch((error) => {
            this.isEditingUsed = null;
            this.editedUsedHours = null;

            this.loading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'An error occurred',
                text: 'Please try again later or contact support.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            });
          });
      }
    },

    saveSubtaskHours(task) {
      this.loading = true;
      task.hoursTotal = this.editedSubtaskHours;
      store
        .dispatch('client/updateSubtask', task)
        .then((res) => {
          this.isEditingSubhours = null;
          this.editedSubtaskHours = null;
          this.getRetainers();

          this.loading = false;
        })
        .catch((error) => {
          this.isEditingSubhours = null;
          this.editedSubtaskHours = null;

          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    saveSubtaskDescription(task) {
      this.loading = true;
      task.description = this.editedSubtaskDescription;
      store
        .dispatch('client/updateSubtask', task)
        .then((res) => {
          this.isEditingSubDescription = null;
          this.editedSubtaskDescription = null;
          this.getRetainers();

          this.loading = false;
        })
        .catch((error) => {
          this.isEditingSubDescription = null;
          this.editedSubtaskDescription = null;

          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    updateInvoiced(task) {},

    updateInvoiced(task) {
      this.loading = true;
      task.invoiced = !task.invoiced;
      task.invoiced = !task.invoiced;

      store
        .dispatch('client/updateRetainer', task)
        .then((res) => {
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    updatePaid(task) {
      this.loading = true;
      task.paid = !task.paid;

      store
        .dispatch('client/updateRetainer', task)
        .then((res) => {
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    updatePaidSubtask(task) {
      this.loading = true;
      task.paid = !task.paid;

      store
        .dispatch('client/updateSubtask', task)
        .then((res) => {
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    updateInvoicedSubtask(task) {
      this.loading = true;
      task.invoiced = !task.invoiced;

      store
        .dispatch('client/updateSubtask', task)
        .then((res) => {
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    toggleExpand(task) {
      task.isExpanded = !task.isExpanded;
    },

    openModalDetail(item) {
      this.detailedItem = item;
      this.isRetainerSidebarActive = true;
    },
    closeModalDetail() {
      // this.modalDetailVisible = false;
      this.detailedItem = null;
      this.newInvoiceFile = null;
    },
    uploadInvoice() {
      // Implement logic to upload the new invoice file and associate it with the current detailed item
      const uploadedFile = {
        id: Math.random(),
        fileName: this.newInvoiceFile.name,
        // Add other properties as needed
      };
      this.detailedItem.invoiceFiles.push(uploadedFile);
      this.newInvoiceFile = null;
    },
    downloadFile(file) {
      // Implement logic to download the selected file
    },

    openModal() {
      this.modalVisible = true;
    },
    closeModal() {
      this.modalVisible = false;
    },

    viewDetails(record) {
      // Implement logic to view details for the selected record
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.user-badge {
  width: 50%;
  align-content: center;
}

.dataRange {
  width: 100%;
  height: 2.57rem;
  padding: 0.375rem 0rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.358rem;
  text-align: center;
}

.no-result-message {
  font-size: 24px;
  color: #d8d6de;
  text-align: center;
  margin-top: 20px;
}

.strikethrough {
  text-decoration: line-through;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
@import '~@core/scss/base/pages/app-todo.scss';
</style>

<style lang="scss" scoped>
span.activeMenu {
  font-weight: 700;
  color: black;
  padding-bottom: 2px;
  box-shadow: inset 0 -2px #6d6e6f;
  transition: padding 0.3s;
  padding: 5px 10px 5px;
  margin-right: 10px;
}

span.passiveMenu {
  font-weight: 400;
  color: #6d6e6f;
  padding-bottom: 2px;
  transition: padding 0.3s;
  padding: 5px 10px 5px;
  margin-right: 10px;
}

span.activeMenu:hover {
  background-color: #f9f8f8;
  padding: 5px 10px 5px;
  cursor: pointer;
  transition: padding 0.3s;
}

span.passiveMenu:hover {
  background-color: #f9f8f8;
  box-shadow: inset 0 -2px #afabac;
  padding: 5px 10px 5px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  cursor: pointer;
  transition: padding 0.3s;
}

.projectTitle {
  font-size: 1.75rem;
  font-weight: 400;
  transition: padding 0.3s;
  border: solid 1px transparent;
  border-radius: 5px;
}

.projectTitle:hover {
  border-color: #ccc;
  background-color: #f9f8f8;
  cursor: pointer;
}

.subTitle {
  font-size: 1.5rem;
  margin-bottom: 0.25rem;
  font-weight: 500;
}

.person {
  display: flex;
  align-items: center;
  padding: 10px;
  border: solid 1px transparent;
  border-radius: 5px;
  transition: border 0.3s;
  height: 110px;
  max-width: 300px;
  min-width: 200px;
  background-color: #fff;
  margin-right: 10px;
  margin-top: 10px;
}

.person:hover {
  background-color: #f9f8f8;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px;
  border-color: #ccc;
}

.contact {
  display: flex;
  align-items: center;
  padding: 10px;
  border: solid 1px transparent;
  border-radius: 5px;
  transition: border 0.3s;
  height: 110px;
  max-width: 300px;
  min-width: 200px;
  background-color: #fff;
  margin-right: 10px;
  margin-top: 10px;
}

.contact:hover {
  background-color: #f9f8f8;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px;
  border-color: #ccc;
}

.contact-details {
  flex-grow: 1;
}

.description {
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: solid 1px transparent;
  transition: border 0.3s;
  border-radius: 5px;

  // background-color: #00000003;
}

.description:hover {
  background-color: #f9f8f8;
  border-radius: 5px;
  cursor: pointer;
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-color: #ccc;
}

.profile-photo {
  width: 42px;
  height: 42px;
  background-color: #ccc;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: white;
}

.initials {
  text-transform: uppercase;
}

.person-details {
  flex-grow: 1;
}

.name {
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.position {
  color: #6d6e6f;
  margin-top: 10px;
}
.close-icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background-color: transparent;
  color: black;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.person:hover .close-icon {
  opacity: 1;
}

.contact-icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background-color: transparent;
  color: black;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.contact:hover .contact-icon {
  opacity: 1;
}

/* TO - DO   */

.todo-header {
  display: flex;
  background-color: #f5f5f5;
}

.header-cell {
  padding: 7px;
  border: 1px solid #e5e5e5;
  font-weight: bold;
}

.task-content {
  display: flex;
  width: 100%;
}

.subtask-content {
  display: flex;
  width: 100%;
  left: 30px;
}

.todo-cell {
  display: flex;
  align-items: center;
  padding: 5px;
  height: 40px;
  border-left: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}

.add-cell {
  display: flex;
  align-items: center;
  padding: 5px;
  height: 40px;
  background-color: #dddddd20;
}

.add-cell:hover {
  background-color: #dddddd40;
  cursor: pointer;
}

.todo-cell:last-child {
  border-right: 1px solid #e5e5e5;
}

.task-name {
  display: flex;
  align-items: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-checkbox {
  position: relative;
}

.custom-checkbox .checkbox-icon {
  width: 15px;
  height: 15px;
  border: 1px solid #ccc;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50px;
}

.custom-checkbox .checkbox-icon:not(.checked)::before {
  content: '\2713';
  font-size: 10px;
  color: #000;
}

.custom-checkbox .checkbox-icon.checked {
  background-color: #198754;
}

.custom-checkbox .checkbox-icon.checked::before {
  content: '\2713';
  font-size: 14px;
  color: #fff;
}

.no-results {
  text-align: center;
  padding: 20px;
  display: none;
}

.no-results.show {
  display: block;
}

.w-97 {
  width: 97%;
}

.w-47 {
  width: 47%;
}
.w-45 {
  width: 45%;
}

.w-42 {
  width: 42%;
}

.w-50 {
  width: 50%;
}

.w-46 {
  width: 46%;
}
.w-44 {
  width: 44%;
}

.w-30 {
  width: 30%;
}

.w-35 {
  width: 35%;
}

.w-37 {
  width: 37%;
}

.w-5 {
  width: 5%;
}

.w-4 {
  width: 4%;
}

.w-3 {
  width: 3%;
}
.w-8 {
  width: 8%;
}

.w-10 {
  width: 10%;
}
.w-15 {
  width: 15%;
}
.w-20 {
  width: 20%;
}

.w-12 {
  width: 12%;
}
.w-16 {
  width: 16%;
}

.w-14 {
  width: 14%;
}

.hover-background:hover {
  background-color: #edeae9;
  cursor: pointer;
  position: relative;
}

.hover-background:hover::after {
  content: '\23F7';
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  color: #bbb;
}

.popoverMenu {
  cursor: pointer;
  padding: 0.5rem;
}

.popoverMenu:hover {
  background-color: rgba(197, 197, 197, 0.05);
  padding: 0.5rem;
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
    border-top-right-radius: 0rem;
    border-top-left-radius: 0rem;
    min-height: 50px;
  }

  + #description-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.cardClass {
  padding: 1.25rem;
}
</style>
