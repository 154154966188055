import { render, staticRenderFns } from "./PreviewClient.vue?vue&type=template&id=7ffbb245&scoped=true&"
import script from "./PreviewClient.vue?vue&type=script&lang=js&"
export * from "./PreviewClient.vue?vue&type=script&lang=js&"
import style0 from "./PreviewClient.vue?vue&type=style&index=0&id=7ffbb245&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ffbb245",
  null
  
)

export default component.exports