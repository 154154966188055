<template>
  <b-overlay :show="formShow" rounded="sm" no-fade>
    <b-card rounded="sm" no-fade>
      <b-row>
        <b-col cols="12" xl="12" lg="12" md="12" sm="12">
          <b-alert variant="secondary" show class="mt-4">
            <div class="alert-body">
              <span class="text-center h5"><strong>Total Duration: </strong> {{ hoursFormat(totalDuration) }}</span>
            </div>
          </b-alert>

          <b-alert variant="success" show class="mt-2" v-if="isBillable == true || isBillable == null">
            <div class="alert-body">
              <span class="text-center h5"><strong>Total Billable Time: </strong> {{ hoursFormat(totalBillableTime) }}</span>
            </div>
          </b-alert>

          <b-alert variant="danger" show class="mt-2" v-if="isBillable == false || isBillable == null">
            <div class="alert-body">
              <span class="text-center h5"><strong>Total Non-Billable Time: </strong> {{ hoursFormat(totalNonBillableTime) }}</span>
            </div>
          </b-alert>
        </b-col>
      </b-row>
    </b-card>

    <b-row>
      <b-col cols="12" xl="12" md="12">
        <!-- Vehiclle Image and Name -->
        <b-card-body v-if="allDetailedReport.length > 0">
          <app-collapse type="margin">
            <app-collapse-item class="overflow-auto" :id="'collapse -'" :title="(client.name ? client.name : 'NO CLIENT') + '  -  ' + hoursFormat(client.duration)" v-for="client in allSummaryReport" :key="client._id">
              <app-collapse-item class="overflow-auto bg-light-secondary pr-5 pl-5" :id="'collapse -'" :title="(project.name ? project.name : 'NO PROJECT') + '  -  ' + hoursFormat(project.duration)" v-for="project in client.children" :key="project._id">
                <b-table striped hover ref="refUserListTable" class="position-relative" :items="project.details" filter-debounce="250" responsive :small="true" head-variant="dark" :fields="tableColumns" show-empty empty-text="No matching records found">
                  <template #cell(date)="data">
                    <small>{{ dateFormat(data.item.timeInterval.start) }}</small>
                    <br />

                    <small>{{ dateFormat(data.item.timeInterval.end) }}</small>
                  </template>

                  <template #cell(projectName)="data">
                    {{ data.value }}
                  </template>
                  <template #cell(description)="data">
                    {{ data.value }}
                  </template>
                  <template #cell(timeInterval)="data">
                    {{ hoursFormat(data.item.timeInterval.duration) }}
                  </template>
                  <template #cell(billable)="data">
                    <b-badge pill v-if="data.value" variant="success">Billable</b-badge>
                    <b-badge pill v-else variant="secondary">
                      Non Billable
                    </b-badge>
                  </template>
                  <template #cell(tags)="data">
                    <b-badge pill v-for="tag in data.value" :key="tag.index" small variant="info">{{ tag.name }}</b-badge>
                  </template>
                </b-table>
              </app-collapse-item>
            </app-collapse-item>
          </app-collapse>
        </b-card-body>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
('bootstrap-vue');
import { BCard, BCardBody, BRow, BCol, BButton, BAlert, BCardHeader, BCardTitle } from 'bootstrap-vue';
import moment from 'moment';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import axiosIns from '@/libs/axios';
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue';
import 'flatpickr/dist/flatpickr.css';
import flatpickr from 'flatpickr';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { BFormDatepicker } from 'bootstrap-vue';
import vSelect from 'vue-select';

export default {
  components: {
    Treeselect,
    BRow,
    BCol,
    BAlert,
    BCard,
    BButton,
    BCardBody,
    BCardHeader,
    BCardTitle,
    flatpickr,
    AppCollapseItem,
    AppCollapse,
    BFormDatepicker,
    vSelect,
  },

  data: function() {
    return {
      workspaceId: '600ab5ea69564b6c1564e1fe',

      headers: {
        'Content-Type': 'application/json',
        'X-Api-Key': 'OGM5M2IyY2EtNGJlYS00ZGZiLWEwZGEtODMxNWUwN2RmYWNk',
      },

      projectOption: [],
      allProjects: [],
      allClients: [],
      allProjectsAndClients: [],
      totalDuration: 0,
      totalBillableTime: 0,
      totalNonBillableTime: 0,
      formShow: true,
      rangeDate: null,
      isBillable: null,
      isNonBillable: null,

      billableStatus: null,
      selectedClients: [],
      selectedProjects: [],
      colours: [
        '#0c1797D0',
        '#ff7b00D0',
        '#2dcca7D0',
        '#d6008aD0',
        '#5b5b5bD0',
        '#f90d1dD0',
        '#e2d810D0',
        '#b31cd4D0',
        '#6c8b6cD0',
        '#64e0f3D0',
        '#c0d40fD0',
        '#d502c9D0',
        '#038da8D0',
        '#ff9d00D0',
        '#3a5f5dD0',
        '#db00d1D0',
        '#40abf1D0',
        '#9c380eD0',
        '#d57300D0',
        '#d4003aD0',
        '#0067aaD0',
        '#d9c810D0',
        '#3c03d3D0',
        '#5a9100D0',
        '#ea1476D0',
        '#005633D0',
        '#e19a00D0',
        '#a727afD0',
        '#0055d3D0',
        '#e8a800D0',
        '#ff087eD0',
        '#a9ab00D0',
        '#9e4cadD0',
        '#e3a300D0',
        '#35007cD0',
        '#dd9d0eD0',
        '#6a2e8aD0',
        '#d17c00D0',
        '#7a005eD0',
        '#d89600D0',
        '#91399bD0',
        '#eaa900D0',
        '#6c005bD0',
        '#e28400D0',
        '#b51864D0',
        '#cc8e00D0',
        '#4f006dD0',
        '#de7600D0',
        '#a0278eD0',
        '#ff9d00D0',
      ],

      allSummaryReport: [],
      allDetailedReport: [],

      tableColumns: [
        { key: 'date', label: 'Date', class: 'text-center' },
        { key: 'userName', label: 'Employee', class: 'text-center' },
        { key: 'projectName', label: 'Project', class: 'text-center' },
        { key: 'description', label: 'Description', class: 'text-center' },
        { key: 'timeInterval', label: 'Duration', class: 'text-center' },
        { key: 'billable', label: 'Billable', class: 'text-center' },
        { key: 'tags', label: 'Tags', class: 'text-center' },
      ],

      paramsClients: {
        contains: 'CONTAINS',
        ids: [],
        status: 'ACTIVE',
        numberOfDeleted: 0,
      },

      paramsProjects: {
        contains: 'CONTAINS',
        ids: [],
        status: 'ACTIVE',
        numberOfDeleted: 0,
      },
    };
  },

  props: {
    startDate: {
      type: String,
      required: true,
    },
    endDate: {
      type: String,
      required: true,
    },
    clientId: {
      type: String,
      required: true,
    },
    clockifyReports: {
      type: Object,
      required: true,
    },
  },

  created() {
    const today = this.getTodayDate();
    const yesterday = this.getYesterdayDate();
    this.rangeDate = '01-01-2023' + ' to ' + String(yesterday);
  },

  computed: {
    filteredProjects() {
      if (this.selectedClients.length > 0) {
        const selectedClientIds = this.selectedClients.map((client) => client);

        selectedClientIds.forEach((ids) => {
          this.paramsClients.ids.push(ids);
        });

        this.getSummaryReport();

        return this.allProjects.filter((project) => selectedClientIds.includes(project.clientId));
      } else {
        this.paramsClients.ids = [];

        this.getSummaryReport();

        return [];
      }
    },
  },

  methods: {
    getAllProjectsAndClients() {
      this.formShow = true;

      const params = {
        'sort-column': 'name',
        'sort-order': 'ASCENDING',
        archived: false,
        'page-size': 5000,
        billable: this.billableStatus,
      };

      axiosIns
        .get(`https://api.clockify.me/api/v1/workspaces/${this.workspaceId}/clients`, { params, headers: this.headers })
        .then((res) => {
          this.allClients = res.data;
          return axiosIns.get(`https://api.clockify.me/api/v1/workspaces/${this.workspaceId}/projects`, { params, headers: this.headers });
        })
        .then((res) => {
          this.allProjects = res.data;
          this.allClients.forEach((client) => {
            client.projects = this.allProjects.filter((project) => project.clientId === client.id);
          });

          this.allProjectsAndClients = this.allClients.filter((client) => client.projects.length > 0);
          const projectsWithoutClients = this.allProjects.filter((project) => {
            return project.clientId === '';
          });

          this.allProjectsAndClients.push({ id: '', name: 'No Client', projects: projectsWithoutClients });

          this.formShow = false;
        })
        .catch((error) => {
          console.log(error);
          this.formShow = false;
        });
    },

    getSummaryReport() {
      this.formShow = true;

      const paramsSummary = {
        dateRangeStart: this.convertToISO(this.startDate),
        dateRangeEnd: this.convertToISO(this.endDate),
        billable: this.billableStatus,
        sortOrder: 'ASCENDING',
        summaryFilter: {
          groups: ['CLIENT', 'PROJECT', 'USER'],
        },
        clients: {
          contains: 'CONTAINS_ONLY',
          ids: [this.clientId],
        },
      };

      axiosIns
        .post(`https://reports.api.clockify.me/v1/workspaces/${this.workspaceId}/reports/summary`, paramsSummary, { headers: this.headers })
        .then((res) => {
          this.totalDuration = res.data.totals[0].totalTime;
          this.totalBillableTime = res.data.totals[0].totalBillableTime;
          this.totalNonBillableTime = res.data.totals[0].totalTime - res.data.totals[0].totalBillableTime;

          this.allSummaryReport = res.data.groupOne;
        })
        .then((res) => {
          this.allDetailedReport = this.clockifyReports.timeentries;

          this.allSummaryReport.forEach((clients) => {
            clients.children.forEach((projects) => {
              projects.children.forEach((users) => {
                if (!projects.details) {
                  projects.details = [];
                }

                const client_id = clients._id;
                const user_id = users._id;

                const matchingDetails = this.allDetailedReport.filter((detail) => detail.clientId === client_id && detail.userId === user_id);

                matchingDetails.forEach((element) => {
                  projects.details.push({ userName: element.userName, projectName: element.projectName, timeInterval: element.timeInterval, billable: element.billable, description: element.description, tags: element.tags });
                });
              });
            });
          });

          this.formShow = false;
        })
        .catch((error) => {
          console.log(error);
          this.formShow = false;
        });
    },

    quickFilter(val) {
      const today = this.getTodayDate();
      const yesterday = this.getYesterdayDate();
      const thisweek = this.getThisWeekDate();
      const lastweek = this.getLastWeekDate();
      const thismonth = this.getThisMonthDate();
      const lastmonth = this.getLastMonthDate();

      switch (val) {
        case 'today':
          this.rangeDate = String(today) + ' to ' + String(today);
          break;

        case 'yesterday':
          this.rangeDate = String(yesterday) + ' to ' + String(yesterday);
          break;

        case 'thisweek':
          this.rangeDate = String(thisweek.startFormatted) + ' to ' + String(thisweek.endFormatted);
          break;

        case 'lastweek':
          this.rangeDate = String(lastweek.startFormatted) + ' to ' + String(lastweek.endFormatted);
          break;

        case 'thismonth':
          this.rangeDate = String(thismonth.startFormatted) + ' to ' + String(thismonth.endFormatted);
          break;

        case 'lastmonth':
          this.rangeDate = String(lastmonth.startFormatted) + ' to ' + String(lastmonth.endFormatted);
          break;

        default:
          break;
      }
    },

    handleClientSelect() {
      this.selectedProjects = [];
    },

    dateFormat(val) {
      return moment(val).format('DD-MM-YYYY HH:mm');
    },

    convertToFirstISO(dateString) {
      const parts = dateString.split('-');
      const isoDate = new Date(Date.UTC(parts[2], parts[1] - 1, parts[0], 0, 0, 0)).toISOString();
      return isoDate;
    },

    convertToSecondISO(dateString) {
      const parts = dateString.split('-');
      const isoDate = new Date(Date.UTC(parts[2], parts[1] - 1, parts[0], 23, 59, 59)).toISOString();
      return isoDate;
    },

    hoursFormat(val) {
      var totalMinutes = Math.floor(val / 60);
      var hours = Math.floor(totalMinutes / 60);
      var minutes = totalMinutes % 60;
      return hours + ' Hours, ' + minutes + ' Minutes';
    },

    getTodayDate() {
      const today = new Date();
      const day = String(today.getDate()).padStart(2, '0');
      const month = String(today.getMonth() + 1).padStart(2, '0'); // Note: January is 0!
      const year = today.getFullYear();
      const todayDate = `${day}-${month}-${year}`;
      return todayDate;
    },

    getYesterdayDate() {
      const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      const day = yesterday.toLocaleDateString('en-GB', options).slice(0, 2);
      const month = yesterday.toLocaleDateString('en-GB', options).slice(3, 5);
      const year = yesterday.toLocaleDateString('en-GB', options).slice(6);
      const yesterdayFormatted = `${day}-${month}-${year}`;
      return yesterdayFormatted;
    },

    getThisWeekDate() {
      const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
      const currentDate = new Date();

      const startOfWeek = new Date(currentDate);
      startOfWeek.setDate(currentDate.getDate() - (currentDate.getDay() - 1)); // Pazartesi olarak ayarlanır
      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(startOfWeek.getDate() + 6); // Bu haftanın son günü

      const startDay = startOfWeek.toLocaleDateString('en-GB', options).slice(0, 2);
      const startMonth = startOfWeek.toLocaleDateString('en-GB', options).slice(3, 5);
      const startYear = startOfWeek.toLocaleDateString('en-GB', options).slice(6);
      const startFormatted = `${startDay}-${startMonth}-${startYear}`;

      const endDay = endOfWeek.toLocaleDateString('en-GB', options).slice(0, 2);
      const endMonth = endOfWeek.toLocaleDateString('en-GB', options).slice(3, 5);
      const endYear = endOfWeek.toLocaleDateString('en-GB', options).slice(6);
      const endFormatted = `${endDay}-${endMonth}-${endYear}`;

      return { startFormatted, endFormatted };
    },

    getLastWeekDate() {
      const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
      const currentDate = new Date();

      const startOfLastWeek = new Date(currentDate);
      startOfLastWeek.setDate(currentDate.getDate() - (currentDate.getDay() - 1) - 7); // Geçen haftanın pazartesi olarak ayarlanır
      const endOfLastWeek = new Date(startOfLastWeek);
      endOfLastWeek.setDate(startOfLastWeek.getDate() + 6); // Geçen haftanın son günü

      const startDay = startOfLastWeek.toLocaleDateString('en-GB', options).slice(0, 2);
      const startMonth = startOfLastWeek.toLocaleDateString('en-GB', options).slice(3, 5);
      const startYear = startOfLastWeek.toLocaleDateString('en-GB', options).slice(6);
      const startFormatted = `${startDay}-${startMonth}-${startYear}`;

      const endDay = endOfLastWeek.toLocaleDateString('en-GB', options).slice(0, 2);
      const endMonth = endOfLastWeek.toLocaleDateString('en-GB', options).slice(3, 5);
      const endYear = endOfLastWeek.toLocaleDateString('en-GB', options).slice(6);
      const endFormatted = `${endDay}-${endMonth}-${endYear}`;

      return { startFormatted, endFormatted };
    },

    getThisMonthDate() {
      const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
      const currentDate = new Date();

      const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

      const startDay = startOfMonth.toLocaleDateString('en-GB', options).slice(0, 2);
      const startMonth = startOfMonth.toLocaleDateString('en-GB', options).slice(3, 5);
      const startYear = startOfMonth.toLocaleDateString('en-GB', options).slice(6);
      const startFormatted = `${startDay}-${startMonth}-${startYear}`;

      const endDay = endOfMonth.toLocaleDateString('en-GB', options).slice(0, 2);
      const endMonth = endOfMonth.toLocaleDateString('en-GB', options).slice(3, 5);
      const endYear = endOfMonth.toLocaleDateString('en-GB', options).slice(6);
      const endFormatted = `${endDay}-${endMonth}-${endYear}`;

      return { startFormatted, endFormatted };
    },

    getLastMonthDate() {
      const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
      const currentDate = new Date();

      const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
      const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);

      const startDay = startOfMonth.toLocaleDateString('en-GB', options).slice(0, 2);
      const startMonth = startOfMonth.toLocaleDateString('en-GB', options).slice(3, 5);
      const startYear = startOfMonth.toLocaleDateString('en-GB', options).slice(6);
      const startFormatted = `${startDay}-${startMonth}-${startYear}`;

      const endDay = endOfMonth.toLocaleDateString('en-GB', options).slice(0, 2);
      const endMonth = endOfMonth.toLocaleDateString('en-GB', options).slice(3, 5);
      const endYear = endOfMonth.toLocaleDateString('en-GB', options).slice(6);
      const endFormatted = `${endDay}-${endMonth}-${endYear}`;

      return { startFormatted, endFormatted };
    },

    convertToISO(dateString) {
      // const parts = dateString.split('-');
      // const isoDate = new Date(parts[2], parts[1] - 1, parts[0]).toISOString();

      const parts = dateString.split('-');
      const isoDate = new Date(Date.UTC(parts[2], parts[1] - 1, parts[0], 0, 0, 0)).toISOString();

      return isoDate;
    },
  },

  mounted() {
    this.getAllProjectsAndClients();

    this.getSummaryReport();
  },
};
</script>

<style lang="scss">
.icon {
  stroke: black;
  fill: none;
  width: 36px;
  height: 36px;
  stroke-width: 0.6666666666666666;
}

.user-title {
  width: 100%;
  font-weight: bold;
}

.user-badge {
  width: 50%;
  align-content: center;
}

.dataRange {
  width: 100%;
  padding: 0.375rem 0rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  text-align: center;
  height: 36px;
}
</style>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.custom-checkbox {
  display: inline-block;
  position: relative;
  padding-left: 35px; /* Uzay ayarlamak için */
  cursor: pointer;
  font-size: 16px;
}

.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

.custom-checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

.custom-checkbox input:checked ~ .checkmark {
  background-color: #2196f3; /* Seçili olduğunda renk */
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.custom-checkbox .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
